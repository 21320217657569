import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router) {
    if (localStorage.getItem('token') && location.href.indexOf('/download/') == -1 && location.href.indexOf('/apkipa/') == -1) {
      this.router.navigate(['/content/home']);
    }
  }
}
