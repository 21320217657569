import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from './baseApi';
import { BaseResponse, BaseSearch } from '../share/base.model';

export class ApplicationInfoType{
  apkDownloadNum: number;
  apkVersion: string;
  appUniqueKey: string;
  id: number;
  ipaDownloadNum: number;
  identifier: string;
  ipaVersion: string;
  logo: string;
  name: string;
  state: boolean;
  updateTime: string;
  downloadUrl: string;
  filesdomain: string;
}
export class ApkInfoType{
  name: string;
  appUniqueKey: string;
  id: number;
  version: string;
  size: string;
  logo: string;
  md5key: string;
  apkPath: string;
  identifier: string;
  updateTime: string;
  filesdomain: string;
}
export class IpaInfoType{
  name: string;
  appUniqueKey: string;
  id: number;
  version: string;
  size: string;
  logo: string;
  md5key: string;
  ipaPath: string;
  identifier: string;
  bundleId: string;
  webClipUrl: string;
  dropSignUrl: string;
  openDropSign: boolean;
  updateTime: string;
  filesdomain: string;
}
export class AddWebclipInput{
  platformId: number;
  appUniqueKey: string;
  webClipUrl: string;
}
export class RechargeInput{
  toUserId: number;
  driveCount: number;
}
export class RechargeInfo{
  id: number;
  ip: string;
  driveCount: number;
  toUserId: number;
  createTime: string;
}
export class OpenAppInput{
  appUniqueKey: string;
  state: boolean;
}
export class OpenDropSignInput{
  appUniqueKey: string;
  openDropSign: boolean;
}
export class DeleteApkInput{
  platformId: number;
  appUniqueKey: string;
}
export class IpaPlistInput{
  ipaurl: string;
  identifier: string;
  version: string;
  appname: string;
  iconUrl: string;
}
@Injectable({
  providedIn: 'root'
})
export class ApplicationApiService extends BaseApiService<any> {

  constructor(@Inject(HttpClient) http) {
    super(http);
  }
  // 获取应用列表
  getApplist(params: BaseSearch<any>): Observable<any>{
    const url = '/app/list';
    return this.get(url, params);
  }
  // 根据appUniqueKey
  getAppinfo(appUniqueKey: string): Observable<any>{
    const url = '/app/info?appUniqueKey=' + appUniqueKey;
    return this.get(url);
  }
  // 根据appUniqueKey
  getApkinfo(appUniqueKey: string): Observable<any>{
    const url = '/app/apkinfo?appUniqueKey=' + appUniqueKey;
    return this.get(url);
  }
  // 根据appUniqueKey
  getIpainfo(appUniqueKey: string): Observable<any>{
    const url = '/app/ipainfo?appUniqueKey=' + appUniqueKey;
    return this.get(url);
  }
  // 根据appUniqueKey删除应用
  deleteApp(appUniqueKey: string): Observable<any>{
    const url = '/app/delete?appUniqueKey=' + appUniqueKey;
    return this.get(url);
  }
  // 添加webclip应用
  addWebclip(params: AddWebclipInput): Observable<any> {
    const url = '/app/addwebclip';
    return this.post(url, params);
  }
  // 获取签发列表
  getFinancList(params: BaseSearch<any>): Observable<any>{
    const url = '/app/financlist';
    return this.get(url, params);
  }
  // 获取签发列表
  getFinancListByTime(params: BaseSearch<any>): Observable<any>{
    const url = '/app/financlistbytime';
    return this.get(url, params);
  }
  // 获取充值列表
  getRechargeList(params: BaseSearch<any>): Observable<any>{
    const url = '/app/rechargelist';
    return this.get(url, params);
  }
  // 添加充值
  addRecharge(params: RechargeInput): Observable<any> {
    const url = '/app/rechargedevice';
    return this.post(url, params);
  }
  openApp(params: OpenAppInput): Observable<any> {
    const url = '/app/openapp';
    return this.post(url, params);
  }
  setOpenDropSign(params: OpenDropSignInput): Observable<any> {
    const url = '/app/opendropsign';
    return this.post(url, params);
  }
  getIpa(ipaPath: string): Observable<any> {
    const url = '/app/getipa?ipaPath=' + ipaPath;
    return this.get(url);
  }
  getDownloadList(params: BaseSearch<any>): Observable<any>{
    const url = '/app/appdownloadlist';
    return this.get(url, params);
  }
  getAppUpdateList(params: BaseSearch<any>): Observable<any>{
    const url = '/app/appupdatelist';
    return this.get(url, params);
  }
  deleteApk(params: DeleteApkInput): Observable<any> {
    const url = '/app/deleteapk';
    return this.post(url, params);
  }
  // 下载企业版签名的ipa应用
  ipaPlist(params: IpaPlistInput): Observable<any>{
    const url = '/app/ipaplist';
    return this.post(url, params);
  }
}
