import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApkipaComponent } from './pages/apkipa/apkipa.component';
import { DownloadComponent } from './pages/download/download.component';
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/content/webclip' },
  { path: 'content', loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'download/:appUuid', component: DownloadComponent },
  { path: 'apkipa/:appUuid', component: ApkipaComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
