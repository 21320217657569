import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import JSEncrypt from 'encryptlong';
import { ClipboardService } from 'ngx-clipboard'
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private router: Router,
    private message: NzMessageService,
    private modal: NzModalService,
    private clipboardCtrl: ClipboardService
  ) { }
  superUser() {
    return '1';
  }
  // 路由跳转
  goNav(nav: string, params?: any): void {
    this.router.navigate([nav], { queryParams: params ? params : '' });
  }
  /**
   * 日志控制: production = false 显示在控制面板
   * level: 1:log 2:info 3:warn 4:error
   * @param   prefix  [prefix description]
   * @param   level   [level description]
   * @param   msg     [msg description]
   * @return          [return description]
   */
  log(prefix: any, level = 1, msg: any = ''): void {
    if (!ConfigService.production) {
      switch (level) {
        case 1:
          window.console.log(prefix, msg);
          break;
        case 2:
          window.console.info(prefix, msg);
          break;
        case 3:
          window.console.warn(prefix, msg);
          break;
        case 4:
          window.console.error(prefix, msg);
          break;
      }
    }
  }
  // 本地存储封装
  write(key: string, value: any): void {
    value = value ? JSON.stringify(value) : '';
    localStorage.setItem(key, value);
  }

  writeString(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  read<T>(key: string): T {
    const value: string = localStorage.getItem(key);
    return (value && value !== 'undefined' && value !== 'null') ? JSON.parse(value) as T : null;
  }

  readString(key: string): string {
    const cache = localStorage.getItem(key);
    return cache ? cache : '';
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  exist(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
  /**
   * 全局提示 1=success 2=error 3=warning
   * @param type 1=success 2=error 3=warning
   */
  showMessage(type: number, msg: string): void {
    let typestr = '';
    switch (type) {
      case 1:
        typestr = 'success';
        break;
      case 2:
        typestr = 'error';
        break;
      case 3:
        typestr = 'warning';
        break;
      default:
        typestr = 'success';
        break;
    }
    this.message.create(typestr, msg);
  }
  showConfirm(title: string, content: string, handerOk: () => void): void {
    this.modal.confirm({
      nzTitle: title,
      nzContent: content,
      nzOkText: '确定',
      nzOkType: 'danger',
      nzOnOk: handerOk,
      nzCancelText: '取消',
      nzOnCancel: () => {}
    });
  }
  // 深拷贝
  deepClone(target): any {
    let cacheValue;
    if (Object.prototype.toString.call(target) === '[object Array]') {
      cacheValue = [];
      let i = target.length;
      while (i--) {
        cacheValue[i] = this.deepClone(target[i]);
      }
      return cacheValue;
    } else if (Object.prototype.toString.call(target) === '[object Object]') {
      cacheValue = {};
      // 注意，此举也会顺带遍历原型链上的属性
      // tslint:disable-next-line: forin
      for (const k in target) {
        cacheValue[k] = this.deepClone(target[k]);
      }
      return cacheValue;
    } else {
      return target;
    }
  }
  // 倒计时
  countDown(event: any, count: number, beforeContent: string, afterContent: string): void {
    // tslint:disable-next-line:one-variable-per-declaration
    let countdown = count, timer;
    // tslint:disable-next-line:only-arrow-functions
    timer = setInterval(function(): void {
        if (countdown === 0) {
            clearInterval(timer);
            event.target.disabled = false;
            event.target.value = beforeContent;
            event.target.style.opacity = 1;
            countdown = count;
        } else {
          event.target.disabled = true;
          event.target.style.opacity = 0.5;
          event.target.value = afterContent + countdown;
          countdown--;
        }
    }, 1000);
  }
  // 单纯倒计时功能
  countnum(count: number, callback: Function, times = 1000) {
    let countdown = count, timer;
    timer = setInterval(function () {
    if (countdown === 0) {
        clearInterval(timer);
        timer = null;
    } else {
        countdown--;
    }
    callback(countdown, timer);
    }, times);
}
  // 时间格式化
  dateFormat(fmt: string, date: any): string { // fmt: 类似于angular自带时间管道格式
    let ret;
    const opt = {
        'y+': date.getFullYear().toString(),        // 年
        'M+': (date.getMonth() + 1).toString(),     // 月
        'd+': date.getDate().toString(),            // 日
        'H+': date.getHours().toString(),           // 时
        'm+': date.getMinutes().toString(),         // 分
        's+': date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    // tslint:disable-next-line:forin
    for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length === 1)
            ? (opt[k])
            : (opt[k].padStart(ret[1].length, '0')));
        }
    }
    return fmt;
  }
  /**
   * @param startDay 开始日期 格式：2020-2-18 || 2020/2/8 || new Date()
   * @param endDay 结束日期
   */
  // 计算天数差的函数，通用
  dateDiff(startDay, endDay): number { // 2020-2-18 || 2020/2/8 || new Date()形式
    if (!startDay || !endDay) { return ; }
    let iDays;
    // startDay和endDay是2020-2-18或2020/2/8格式
    if (typeof startDay === 'string') {
      const oDate1: any = new Date(startDay);
      const oDate2: any =  new Date(endDay);
      iDays = Math.abs(oDate2 - oDate1) / 1000 / 60 / 60 / 24; // 把相差的毫秒数转换为天数
    } else { // startDay： new Date() 格式
      iDays = Math.abs(endDay - startDay) / 1000 / 60 / 60 / 24; // 把相差的毫秒数转换为天数
    }
    return  Math.floor(iDays) + 1;
  }

  rsaEncrypt(params: any): any {
    const rsaPublicKey = ConfigService.rsaPublicKey;
    const encrypt = new JSEncrypt(); // 创建加密对象实例
    encrypt.setPublicKey(rsaPublicKey); // 设置公钥
    console.log('需要加密的信息:', JSON.stringify(params));
    const encryptParams = encrypt.encryptLong(JSON.stringify(params)); // 对内容进行加密
    return encryptParams;
  }
  // 复制功能
  webcopy(msg: string){
    this.clipboardCtrl.copyFromContent(msg);
  }
}
