import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { zh_CN } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { AuthInterceptorService } from './service/auth-interceptor.service';
import zh from '@angular/common/locales/zh';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { ClipboardModule } from 'ngx-clipboard';
registerLocaleData(zh);
import { DownloadComponent } from './pages/download/download.component';
import { ApkipaComponent } from './pages/apkipa/apkipa.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
@NgModule({
  declarations: [
    AppComponent,
    DownloadComponent,
    ApkipaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NzButtonModule,
    NzRateModule,
    NzProgressModule,
    NgxQRCodeModule,
    NzGridModule,
    ClipboardModule
  ],
  exports: [
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: NzMessageService },
    { provide: NzModalService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
