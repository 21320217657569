import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../service/config.service';
import { BaseResponse } from '../../share/base.model';
import { CommonService } from '../../service/common.service';
import {
  ApplicationApiService,
  ApkInfoType,
  IpaInfoType,
  ApplicationInfoType,
  IpaPlistInput,
} from '../../api/applicationApi.service';
@Component({
  selector: 'app-apkipa',
  templateUrl: './apkipa.component.html',
  styleUrls: ['./apkipa.component.scss'],
})
export class ApkipaComponent implements OnInit {
  title = '';
  version = '1.3.9';
  ratings = {
    headcount: 100,
    averageScore: '4.9',
    score1: 2,
    score2: 3,
    score3: 5,
    score4: 13,
    score5: 77,
  };
  information = [
    {
      title: '安装包大小 Size',
      description: '65.6M'
    },
    {
      title: '兼容性(iOS)',
      description: '兼容iOS8.0或更高版本。兼容iOS8.0或更高版本。兼容iOS8.0或更高版本。兼容iOS8.0或更高版本。兼容iOS8.0或更高版本。兼容iOS8.0或更高版本。'
    },
    {
      title: '兼容性(Android)',
      description: '需要Android6.0或者更高版本的安卓手机。需要Android6.0或者更高版本的安卓手机。需要Android6.0或者更高版本的安卓手机。'
    },
    {
      title: '语言',
      description: '简体中文'
    },
    {
      title: '年龄分级',
      description: '限4岁以上'
    },
    {
      title: '价格',
      description: '免费'
    },
  ];
  isPhone: boolean;
  appUuid: string;
  provsUrl = '';
  confUrl = '';
  apkInfo = new ApkInfoType();
  ipaInfo = new IpaInfoType();
  appInfo = new ApplicationInfoType();
  ipaplistInput = new IpaPlistInput();
  platform = '';
  iosInfo = '';
  url = location.href;
  webclipUrl = '';

  signprocessNum = 0;
  firstClick = true;
  canDownloadApk = true;
  show404 = false;
  filesdomain = '';
  constructor(
    private activeRoute: ActivatedRoute,
    private applicationApi: ApplicationApiService,
    private coms: CommonService
  ) { }

  ngOnInit(): void {
    // this.provsUrl = ConfigService.apiUrl + '/auth/mprovs';
    // this.confUrl = ConfigService.apiUrl + '/auth/mconf?appUuid=' + this.appUuid;
    this.appUuid = this.activeRoute.snapshot.paramMap.get('appUuid');
    // this.iosInfo = this.activeRoute.snapshot.paramMap.get('iosInfo');
    if (!this.appUuid) {
      this.coms.showMessage(2, '非法访问!!!');
      return;
    }
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) { // 移动端
      this.isPhone = true;
    } else {
      this.isPhone = false;
      return;
    }
    // 判断移动平台
    this.isIphone();
  }

  // 判断移动平台
  isIphone(): void {
    this.getAppInfo();
    const ua = navigator.userAgent.toLowerCase();
    // console.log(ua);
    // const isWeixin = ua.indexOf('micromessenger') !== -1;
    // tslint:disable-next-line:max-line-length
    const isIos = ua.indexOf('iphone') !== -1 && ua.indexOf('applewebkit/') !== -1 && ua.indexOf('version/') !== -1 && ua.indexOf('safari/') !== -1;
    // console.log(isIos);
    // 并且是苹果系统
    if (!isIos) {
      this.platform = 'android';
      this.getApkInfo();
    } else {
      this.platform = 'ios';
      this.getIpaInfo();
    }
  }
  // 获取应用信息
  getAppInfo(): void{
    this.applicationApi.getAppinfo(this.appUuid).subscribe(
      (res: BaseResponse<ApplicationInfoType>) => {
        if (res.code === 0){
          // this.coms.log('获取app应用信息', 1, res.data);
          this.appInfo = res.data;
          this.filesdomain = res.data.filesdomain;
          if(!this.appInfo || !this.appInfo.state) {
            this.show404 = true;
            this.coms.showMessage(2, '应用已下架或删除!');
          }
        } else{
          this.coms.showMessage(2, res.msg);
        }
      },
      (err) => {
        this.coms.log('获取应用信息发生错误', 4, err);
      }
    );
  }
  // 查询对应apk应用信息
  getApkInfo(): void{
    this.applicationApi.getApkinfo(this.appUuid).subscribe(
      (res: BaseResponse<ApkInfoType>) => {
        if (res.code === 0){
          // this.coms.log('获取apk应用信息', 1, res.data);
          this.apkInfo = res.data;
        } else{
          this.coms.showMessage(2, res.msg);
        }
      },
      (err) => {
        this.coms.log('获取应用信息发生错误', 4, err);
      }
    );
  }
  // 查询对应ipa应用信息
  getIpaInfo(): void{
    this.applicationApi.getIpainfo(this.appUuid).subscribe(
      (res: BaseResponse<IpaInfoType>) => {
        if (res.code === 0){
          // this.coms.log('获取ipa应用信息', 1, res.data);
          this.ipaInfo = res.data;
          this.ipaplistInput.appname = this.ipaInfo.name;
          this.ipaplistInput.iconUrl = this.ipaInfo.filesdomain + this.ipaInfo.logo;
          this.ipaplistInput.identifier = this.ipaInfo.identifier;
          this.ipaplistInput.ipaurl = this.ipaInfo.filesdomain + this.ipaInfo.ipaPath;
          this.ipaplistInput.version = this.ipaInfo.version;
          this.webclipUrl = res.data.webClipUrl;
        } else{
          this.coms.showMessage(2, res.msg);
        }
      },
      (err) => {
        this.coms.log('获取应用信息发生错误', 4, err);
      }
    );
  }
  // 安装极速版
  quicklyApp(url: string): void {
    const tagA1: any = document.createElement('a');
    tagA1.href = this.webclipUrl;
    // tagA1.href = url;
    tagA1.style.display = 'none';
    document.body.appendChild(tagA1);
    tagA1.click();
    document.body.removeChild(tagA1);
    this.getTrustFile();
  }
  // 自动跳转信任
  getTrustFile(times = 2000): void{
    // 自动跳转文件
    setTimeout(() => {
      const tagA2: any = document.createElement('a');
      tagA2.href = ConfigService.apiUrl + '/auth/mprovs';
      tagA2.style.display = 'none';
      document.body.appendChild(tagA2);
      tagA2.click();
      document.body.removeChild(tagA2);
    }, times);
    // const tagA2: any = document.createElement('a');
    // tagA2.href = ConfigService.apiUrl + '/auth/mprovs';
    // tagA2.style.display = 'none';
    // document.body.appendChild(tagA2);
    // tagA2.click();
    // document.body.removeChild(tagA2);
  }
  downloadApkFile(): void {
    if(!this.apkInfo) {
      this.coms.showMessage(2, '暂未配置安卓应用!');
      return;
    }
    const tagA3: any = document.createElement('a');
    tagA3.href = this.filesdomain + this.apkInfo.apkPath;
    tagA3.style.display = 'none';
    document.body.appendChild(tagA3);
    tagA3.click();
    document.body.removeChild(tagA3);
    this.canDownloadApk = false;
    // this.applicationApi.downloadApk(this.appUuid).subscribe(
    //   (res: BaseResponse<{}>) => {
    //     if (res.code === 0){
    //       this.canDownloadApk = true;
    //     } else{
    //       this.coms.showMessage(2, res.msg);
    //     }
    //   },
    //   (err) => {
    //     this.coms.log('统计apk下载发生错误', 4, err);
    //   }
    // );
  }
  downloadMobileConfig(): void{
    const tagA1: any = document.createElement('a');
    // tslint:disable-next-line:max-line-length
    // const confUrl = ConfigService.apiUrl + '/auth/mconf?appUuid=' + this.appUuid + '&appName=' + this.ipaInfo.name  + '&nextInfo=授权安装进入下一步' + '&description=该配置文件将帮助用户获取当前ios设备的UDID号码.This profile will be used to find and display your current device\'s UDID.';
    // tslint:disable-next-line:max-line-length
    // const confUrl = ConfigService.apiUrl + '/auth/mconf?extra=' + this.coms.infoTobase64(ConfigService.redictUrl) + '&appUuid=' + this.appUuid;
    // console.log(this.ipaplistInput);
    if(!this.ipaplistInput.appname || !this.ipaplistInput.iconUrl || !this.ipaplistInput.identifier || !this.ipaplistInput.ipaurl || !this.ipaplistInput.version){
      this.coms.showMessage(2, '参数不全!');
      return;
    }
    this.applicationApi.ipaPlist(this.ipaplistInput).subscribe(
      (res: any) => {
        if(res['data']){
          tagA1.href = "itms-services://?action=download-manifest&url=" + res['data'];
          tagA1.style.display = 'none';
          document.body.appendChild(tagA1);
          tagA1.click();
          document.body.removeChild(tagA1);
          // this.getTrustFile();
          this.coms.countnum(100, (num, tim)=>{
            this.signprocessNum = 100 - num;
          }, 200);
        }else{
          this.coms.showMessage(2, res['msg']);
        }
      }
    );
  }
}
