<!-- 移动端 -->
<div *ngIf="isPhone && webclipInfo.downloadUrl && !loading" class="wrap">

    <div class="wechat-shadow" *ngIf="isWeixin">
        <img src="assets/images/safari.png">
    </div>

    <div class="install-wrap">
        <div class="logo">
            <img [src]="webclipInfo['icon']" alt="logo">
        </div>
        <div class="install">
            <h2>{{webclipInfo['name']}}</h2>
            <div>
                <nz-progress  *ngIf="signprocessNum != 0" nzStrokeLinecap="round" 
                    nzType="circle"
                    [nzWidth]="40"
                    [nzPercent]="signprocessNum">
                </nz-progress>
            </div>
            <button *ngIf="signprocessNum == 0" class="install-btn" nz-button nzType="primary"
                (click)="downloadMobileConfig(webclipInfo['downloadUrl'])">{{webclipInfo['clickText']}}</button>

        </div>

        <div class="download-ratings">
            <!-- <h3>评分及评论</h3>
            <div class="ratings-left">
                <p>{{ratings.averageScore}}</p>
                <p>满分5分</p>
            </div> -->
            <ul>
                <li>
                    <nz-rate class="ratings-rate" [ngModel]="5" nzDisabled></nz-rate>
                    <nz-progress class="nz-progress" 
                        [nzPercent]="ratings.score5 / ratings.headcount * 100" 
                        nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
                </li>
                <!-- <li>
                    <nz-rate class="ratings-rate" [ngModel]="4" nzDisabled></nz-rate>
                    <nz-progress class="nz-progress" [nzPercent]="ratings.score4 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
                </li>
                <li>
                    <nz-rate class="ratings-rate" [ngModel]="3" nzDisabled></nz-rate>
                    <nz-progress class="nz-progress" [nzPercent]="ratings.score3 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
                </li>
                <li>
                    <nz-rate class="ratings-rate" [ngModel]="2" nzDisabled></nz-rate>
                    <nz-progress class="nz-progress" [nzPercent]="ratings.score2 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
                </li>
                <li>
                    <nz-rate class="ratings-rate" [ngModel]="1" nzDisabled></nz-rate>
                    <nz-progress class="nz-progress" [nzPercent]="ratings.score1 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
                </li> -->
            </ul>
        </div>
    </div>

    <div class="image-tip">
        <img [src]="webclipInfo['installDescImg']">
    </div>
</div>
<!-- pc端 -->
<div *ngIf="!isPhone" class="download-pc">
    <div class="download-pc-content">
        <!-- <div class="pc-img">
            <img src="" alt="">
        </div> -->
        <!-- <h2 class="pc-title">{{title}}</h2> -->
        <ngx-qrcode [elementType]="'scanlogin'" [width]="300" [value]="qrurl" cssClass="qrcode-class"
            errorCorrectionLevel="L">
        </ngx-qrcode>
        <!-- <p class="pc-tips">请扫码跳转下载</p> -->
    </div>
    <!-- <video controls src="../../../assets/supersignipa.mov" controlsList="nodownload"></video> -->
</div>
<!-- 404 -->
<div *ngIf="isPhone && !webclipInfo.downloadUrl && !loading" class="download-pc">
    <div class="download-pc-content">
        <p>404</p>
        <!-- <div class="pc-img">
            <img src="" alt="">
        </div> -->
    </div>
</div>