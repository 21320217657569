import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from '../service/config.service';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService<T> {
  url = ConfigService.apiUrl;
  defualtHeader = new HttpHeaders();
  constructor(
    private http: HttpClient
  ) {
    // this.defualtHeader = this.defualtHeader.append('access-control-allow-origin', '*');
    // this.defualtHeader = this.defualtHeader.append('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
  }
  // 获取api
  private getApiUrl(model: string): string {
      if (!model) {
        throwError('无效地址');
      }
      return this.url + model;
  }
  // get 请求
  public get(url: string, params?: any): Observable<T>  {
    // console.log(params);
    let urlSearchParams = new HttpParams();
    if (typeof (params) === 'object') {
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                let element = params[key];
                if (element instanceof Date) {
                    element = element.toDateString();
                }
                // 处理url嵌套一层对象参数
                if (typeof (element) === 'object') {
                  // tslint:disable-next-line:forin
                  for (const k in element) {
                    let ele = element[k];
                    if (ele instanceof Date) {
                      ele = ele.toDateString();
                    }
                    urlSearchParams = urlSearchParams.append(k, ele);
                  }
                } else {
                  urlSearchParams = urlSearchParams.append(key, element);
                }
            }
        }
    } else if (typeof (params) === 'string') {
        url += '?' + params;
    } else if (params instanceof HttpParams) {
        urlSearchParams = params;
    }
    url = this.getApiUrl(url);
    return this.http.get<T>(url, {
      params: urlSearchParams,
      headers: this.defualtHeader,
      withCredentials: false
    }).pipe(
      // retry(3), // 重新请求3次
      catchError(this.handleError)
    );
  }
  // post 请求
  public post(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    url = this.getApiUrl(url);
    options = {headers: this.defualtHeader, withCredentials: false};
    return this.http.post<T>(url, body, options).pipe(
      catchError(this.handleError)
    );
  }
  // put 请求
  public put(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
    url = this.getApiUrl(url);
    options = {headers: this.defualtHeader, withCredentials: false};
    return this.http.put<T>(url, body, options).pipe(
      catchError(this.handleError)
    );
  }
  // delete 请求
  public delete(url: string, options?: any): Observable<HttpEvent<T>>  {
      url = this.getApiUrl(url);
      options = {headers: this.defualtHeader, withCredentials: false};
      return this.http.delete<T>(url, options).pipe(
        catchError(this.handleError)
      );
  }
  // 错误统一处理
  private handleError(error: HttpErrorResponse): Observable<any> {
    let msg = '请求发生错误!';
    if (error.error instanceof ErrorEvent) {
      // console.error('An error occurred:', error.error.message);
      console.error('请求发生错误!', error.error.message);
    } else {
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      if(error.status == 401) {
        msg = '用户认证失效，请重新登录'
      }else{
        msg = error.error;
      }
    }
    return throwError(msg);
  }

}
