import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from './baseApi';
import { BaseResponse, BaseSearch } from '../share/base.model';
export interface LoginInputType {
  userName: string;
  password: string;
  captcha: number;
}
export interface RegisterInputType {
  userName: string;
  password: string;
}
export interface CheckauthType {
  userName: string;
  password: string;
  authType: number;
  id: number;
  authCode: string;
  code?: number;
  googleQrcodeUrl?: string;
  googleQrcodeSecret?: string;
}
export class SslType{
  id: number;
  domain: string;
  pemPath: string;
  keyPath: string;
  createTime: string;
}
export class SslInput{
  domain: string;
  pemPath: string;
  keyPath: string;
}
export class PlatformType{
  id: number;
  userId: number;
  name: string;
  createTime: string;
}
export class PlatformInput{
  userId: number;
  name: string;
}
export class WebclipInput{
  name: string;
  platformId: number;
  icon: string;
  wapUrl: string;
  instructions: string;
  clickText: string;
  installDescImg: string;
  isRemove: boolean;
  isFullScreen: boolean;
}
export class WebclipType{
  id: number;
  platformId: number;
  userId: number;
  appUniqueKey: string;
  name: string;
  wapUrl: string;
  icon: string;
  downloadUrl: string;
  instructions: string;
  clickText: string;
  installDescImg: string;
  apkPath: string;
  apkVersion: string;
  md5key: string;
  isRemove: boolean;
  isFullScreen: boolean;
  createTime: string;
}
export class UpdateMpInput{
  mpBase64Data: string;
}
export class MultiLangInput{
  name: string;
  instructions: string;
  installDescImg: string;
  clickText: string;
}
export class MultiLangType{
  id: number;
  userId: number;
  name: string;
  instructions: string;
  installDescImg: string;
  clickText: string;
  createTime: string;
}
export class ApkadressInput{
  platformId: number;
  md5key: string;
  apkPath: string;
  appUniqueKey: string;
  apkVersion: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthApiService extends BaseApiService<any>{

  constructor( @Inject(HttpClient) http ) {
    super(http);
  }
  // 登录
  login(params: LoginInputType): Observable<any> {
    const url = '/auth/login';
    return this.post(url, params);
  }
  // 注册
  register(params: RegisterInputType): Observable<any> {
    const url = '/auth/register';
    return this.post(url, params);
  }
  // 获取语音验证码
  getCaptcha(): Observable<any> {
    const url = '/auth/captcha';
    return this.get(url);
  }
  // 令牌验证
  checkauth(params: CheckauthType): Observable<any> {
    const url = '/auth/check';
    return this.post(url, params);
  }
  // 获取域名列表
  getSslList(params: BaseSearch<any>): Observable<any> {
    const url = '/auth/ssllist';
    return this.get(url, params);
  }
  // 添加域名
  addSsl(params: SslInput): Observable<any> {
    const url = '/auth/sslcreate';
    return this.post(url, params);
  }
  // 删除域名
  deleteSsl(id: number): Observable<any> {
    const url = '/auth/ssldelete?id=' + id;
    return this.get(url);
  }
  // 获取平台列表
  getPlatformList(params: BaseSearch<any>): Observable<any> {
    const url = '/auth/platformlist';
    return this.get(url, params);
  }
  // 添加平台
  addPlatform(params: PlatformInput): Observable<any> {
    const url = '/auth/platformcreate';
    return this.post(url, params);
  }
  // 获取免签应用
  getWebclip(appUuid:string): Observable<any> {
    const url = '/auth/webclip?appUuid=' + appUuid;
    return this.get(url);
  }
  // 获取免签应用列表
  getWebclipList(params: BaseSearch<any>): Observable<any> {
    const url = '/auth/webcliplist';
    return this.get(url, params);
  }
  // 创建免签应用
  createWebclip(params: WebclipInput): Observable<any> {
    const url = '/auth/webclipcreate';
    return this.post(url, params);
  }
  // 删除免签应用
  deleteWebclip(appUniqueKey: string): Observable<any> {
    const url = '/auth/webclipdelete?appUniqueKey=' + appUniqueKey;
    return this.get(url);
  }
  // 更新跳转信用文件
  updatemp(params: UpdateMpInput): Observable<any> {
    const url = '/auth/updatemp';
    return this.post(url, params);
  }
  // 
  multiLangList(params: BaseSearch<any>): Observable<any> {
    const url = '/auth/multilanglist';
    return this.get(url, params);
  }
  // 
  addMultiLang(params: MultiLangInput): Observable<any> {
    const url = '/auth/addmultilang';
    return this.post(url, params);
  }
  // 删除
  deleteMultiLang(id: number): Observable<any> {
    const url = '/auth/deletemultilang?id=' + id;
    return this.get(url);
  }
  getAlioss(): Observable<any> {
    const url = '/auth/alioss';
    return this.get(url);
  }
  updateAlioss(params: any): Observable<any> {
    const url = '/auth/upalioss';
    return this.post(url, params);
  }
  openHost(params: any): Observable<any> {
    const url = '/auth/upopenhost';
    return this.post(url, params);
  }
  updateSignUrl(params: any): Observable<any> {
    const url = '/auth/upsignurl';
    return this.post(url, params);
  }
  updateAllowHost(params: any): Observable<any> {
    const url = '/auth/updatehost';
    return this.post(url, params);
  }
  updatepwd(params: any): Observable<any> {
    const url = '/auth/updatepwd';
    return this.post(url, params);
  }
  userInfo(): Observable<any> {
    const url = '/auth/userinfo';
    return this.get(url);
  }
  userList(params: BaseSearch<any>): Observable<any> {
    const url = '/auth/userlist';
    return this.get(url, params);
  }
  updateApkAdress(params: ApkadressInput): Observable<any> {
    const url = '/auth/apkadress';
    return this.post(url, params);
  }
  getFilesDomain(): Observable<any> {
    const url = '/auth/filesdomain';
    return this.get(url);
  }
  updateFilesDomain(params: any): Observable<any> {
    const url = '/auth/upfilesdomain';
    return this.post(url, params);
  }
}
