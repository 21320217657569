import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    // const qrcodeid = localStorage.getItem('qrcodeid');
    const headers = {
      Authorization: `${token}`,
    };
    // if(qrcodeid) {
    //   headers = Object.assign(headers, {'QRCODEID': qrcodeid})
    // }
    if (token) {
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request).pipe(
      tap(
        (res) => {
        }
      ),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 401:
              this.router.navigate(['/auth/login']);
              localStorage.removeItem('token');
              break;
            default:
              break;
          }
        }
        return throwError(error);
      }
      )
    );

  }
}
