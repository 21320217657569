import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // static apiUrl = 'https://www.vorn.cc/api';
  static apiUrl = '/api';
  // static apiUrl = 'http://43.231.184.173:8000/api';
  // static apiUrl = 'http://192.168.1.3:8800/api';
  static rsaPublicKey = `-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDYznPWNcJC9rnpCsswd14+nD0R7cyg/jZneENU12xVS4dXxNn/kD7teLeWF5W1F73apw7yyeDBvZSBinuVZtOX3R6yT8MXX4vNYpOkOUXi9EynZKMBrfW8bxPeCiTUCw+VDMiIfovyLb5stdf/YJZ5mSbiCqbpWcuKayZItlMFSQIDAQAB-----END PUBLIC KEY-----`;
  // static apiUrl = 'http://awe.quntingcui.com';
  // static apiUrl = 'https://servers.easontest.com';
  static production = false;
  constructor() { }
}
