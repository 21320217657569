import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../service/config.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BaseResponse } from '../../share/base.model';
import { AuthApiService, WebclipType } from '../../api/authApi.service';
@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  isPhone = false;
  appUuid = '';
  qrurl = location.href;
  webclipInfo = new WebclipType();
  ratings = {
    headcount: 100,
    averageScore: '4.9',
    score1: 2,
    score2: 3,
    score3: 5,
    score4: 13,
    score5: 77,
  };
  signprocessNum = 0;
  isWeixin = false;
  isSafari = false;
  loading = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private authApi: AuthApiService,
    private message: NzMessageService
  ) { }

  ngOnInit(): void {
    this.isWechat();
    this.appUuid = this.activeRoute.snapshot.paramMap.get('appUuid');
    if (!this.appUuid) {
      this.message.create('error', '非法访问!!!');
      return;
    }
    this.getWebclipInfo();
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) { // 移动端
      this.isPhone = true;
    } else {
      this.isPhone = false;
      return;
    }
  }
  getWebclipInfo() {
    this.loading = true;
    this.authApi.getWebclip(this.appUuid).subscribe(
      (res: BaseResponse<any>) => {
        if (res.code === 0) {
          this.webclipInfo = res.data;
        } else {
          this.message.create('error', res.msg);
        }
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.message.create('error', err);
      }
    );
  }
  isWechat() {
    const ua = navigator.userAgent.toLowerCase();
    this.isWeixin = ua.indexOf('micromessenger') !== -1;
    this.isSafari = ua.indexOf('iphone') !== -1 && ua.indexOf('applewebkit/') !== -1 && ua.indexOf('version/') !== -1 && ua.indexOf('safari/') !== -1;
  }
  // ios签名等待进度条效果
  showProcess() {
    const processNumber = 100;
    const processTimer = setInterval(() => {
      this.signprocessNum = this.signprocessNum + Math.floor(Math.random()*(Math.floor((processNumber - this.signprocessNum)/2)));
      if(this.signprocessNum == 100){
        clearInterval(processTimer);
      }
    }, 1000);
  }
  downloadMobileConfig(){
    this.showProcess();
    if(this.isSafari){
      this.getFile(this.webclipInfo.downloadUrl);
      this.getTrustFile();
    }else{
      if(!this.webclipInfo.apkPath){
        this.message.create('error', '未配置对应安卓应用!');
        this.signprocessNum = 100;
        setTimeout(() => {
          this.signprocessNum = 0;
        }, 500);
        return;
      }
      this.getFile(this.webclipInfo.apkPath);
    }
  }
  getFile(url: string) {
    const tagA1: any = document.createElement('a');
    // tagA1.href = this.webclipUrl;
    tagA1.href = url;
    tagA1.style.display = 'none';
    document.body.appendChild(tagA1);
    tagA1.click();
    document.body.removeChild(tagA1);
    setTimeout(() => {
      this.signprocessNum = 100;
      setTimeout(() => {
        this.signprocessNum = 0;
      }, 1000);
    }, 10000);
  }
  // 自动跳转信任
  getTrustFile(): void{
    // 自动跳转文件
    setTimeout(() => {
      const tagA2: any = document.createElement('a');
      tagA2.href = ConfigService.apiUrl + '/auth/mprovs';
      tagA2.style.display = 'none';
      document.body.appendChild(tagA2);
      tagA2.click();
      document.body.removeChild(tagA2);
      this.signprocessNum = 100;
      setTimeout(() => {
        this.signprocessNum = 0;
      }, 1000);
    }, 2500);
  }
}
