<!-- 移动端 -->
<div *ngIf="isPhone && !show404" class="download">
    <div class="download-top">
        <div *ngIf="platform == 'ios'">
            <div class="download-img"><img [src]="filesdomain + ipaInfo.logo" alt=""></div>
            <div class="download-title">
                <h2>{{ipaInfo.name}}</h2>
                <button *ngIf="signprocessNum == 0" nz-button nzType="primary" class="install" (click)="downloadMobileConfig()">点击安装</button>
                <div *ngIf="signprocessNum != 0" class="progress-area">
                    <nz-progress [nzPercent]="signprocessNum"></nz-progress>
                    <!-- <span *ngIf="signprocessNum == 100" class="success-info">一键信任</span> -->
                </div>
            </div>
            <div class="download-gif">
                <!-- <i class="iconfont icon-issue iconfont-issue"></i>
                <a>安装教程</a><br/> -->
                <button *ngIf="signprocessNum == 100" nz-button nzType="primary" class="install" (click)="getTrustFile(0)">一键信任</button>
                <button *ngIf="webclipUrl" nz-button nzType="primary" class="install" (click)="quicklyApp(webclipUrl)">安装极速版</button>
                <!-- <a *ngIf="signprocessNum == 100" (click)="getTrustFile()">一键信任</a><br/>
                <a *ngIf="webclipUrl" (click)="quicklyApp(webclipUrl)">安装极速版</a> -->
            </div>
        </div>
        <div *ngIf="platform == 'android'">
            <div class="download-img"><img [src]="filesdomain + apkInfo.logo" alt=""></div>
            <div class="download-title">
                <h2>{{apkInfo.name}}</h2>
                <button *ngIf="canDownloadApk" nz-button nzType="primary" class="install" (click)="downloadApkFile()">下载安装</button>
                <button nz-button nzType="primary" class="install" *ngIf="!canDownloadApk">应用下载中...</button>
            </div>
            <div class="download-gif">
                <!-- <i class="iconfont icon-issue iconfont-issue"></i>
                <a href="">安装教程</a> -->
            </div>
        </div>
        <div>
            <span class="score">{{ratings.averageScore}}</span>
            <nz-rate class="nz-rate" [ngModel]="ratings.averageScore" nzDisabled></nz-rate>
            <span>4+</span>
        </div>
        <div>
            <span>1w+评分 Rating</span>
            <span>年龄 Age</span>
        </div>
    </div>
    <div class="download-intro">
        <h3>简介</h3>
        <!-- <p>uusing</p> -->
    </div>
    <div class="download-ratings">
        <h3>评分及评论</h3>
        <div class="ratings-left">
            <p>{{ratings.averageScore}}</p>
            <p>满分5分</p>
        </div>
        <ul>
            <li>
                <nz-rate class="ratings-rate" [ngModel]="5" nzDisabled></nz-rate>
                <nz-progress class="nz-progress" 
                    [nzPercent]="ratings.score5 / ratings.headcount * 100" 
                    nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
            </li>
            <li>
                <nz-rate class="ratings-rate" [ngModel]="4" nzDisabled></nz-rate>
                <nz-progress class="nz-progress" [nzPercent]="ratings.score4 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
            </li>
            <li>
                <nz-rate class="ratings-rate" [ngModel]="3" nzDisabled></nz-rate>
                <nz-progress class="nz-progress" [nzPercent]="ratings.score3 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
            </li>
            <li>
                <nz-rate class="ratings-rate" [ngModel]="2" nzDisabled></nz-rate>
                <nz-progress class="nz-progress" [nzPercent]="ratings.score2 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
            </li>
            <li>
                <nz-rate class="ratings-rate" [ngModel]="1" nzDisabled></nz-rate>
                <nz-progress class="nz-progress" [nzPercent]="ratings.score1 / ratings.headcount * 100" nzStrokeColor="#666" [nzShowInfo]="false"></nz-progress>
            </li>
        </ul>
    </div>
    <div class="download-version">
        <h3>新功能</h3>
        <p *ngIf="platform == 'ios'">版本 {{ipaInfo.version}}</p>
        <p *ngIf="platform == 'android'">版本 {{apkInfo.version}}</p>
    </div>
    <div class="download-msg">
        <h3>信息</h3>
        <ul>
            <li>
                <span>安装包大小</span>
                <span *ngIf="platform == 'ios'">{{ipaInfo.size}}M</span>
                <span *ngIf="platform == 'android'">{{apkInfo.size}}M</span>
            </li>
        </ul>
    </div>
    <div class="trust-area" *ngIf="platform == 'ios'">
        <img src="/assets/images/trust.png" alt="">
    </div>
    <p class="download-footer">
        免责声明:<br>
        本网站仅提供下载托管，App内容相关事项由上传者负责，与本网站无关。
    </p>
</div>
<div *ngIf="isPhone && show404" class="no-data">
    <h4>
        404
    </h4>
</div>
<!-- pc端 -->
<div *ngIf="!isPhone" class="download-pc">
    <div class="download-pc-content">
        <!-- <div class="pc-img">
            <img src="" alt="">
        </div> -->
        <!-- <h2 class="pc-title">{{title}}</h2> -->
        <ngx-qrcode [elementType]="'scanlogin'" 
            [width]="300"
            [value]="url" 
            cssClass="qrcode-class" 
            errorCorrectionLevel="L">
        </ngx-qrcode>
        <p class="pc-tips">请使用原相机扫码下载</p>
    </div>
    <!-- <video controls src="../../../assets/supersignipa.mov" controlsList="nodownload"></video> -->
</div>
